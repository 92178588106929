<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-clipboard"></i> Fold Distribution</h2>

        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input type="number" v-model="filter.width" class="form-control" placeholder="Width"/>
                            </div>
                            <div class="form-group mr-1">
                                <input type="number" v-model="filter.height" class="form-control" placeholder="Height"/>
                            </div>
                            <div class="form-group mr-1">
                                <input type="file" id="upload" ref="file" @change="readFileAsString">
                            </div>

                            <button type="button" class="btn searchBtn text-white mr-1" style="background-color: #383838" @click="calculate"><i class="fa fa-play" title="Search"></i> <span>Calculate</span></button>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-7 col-sm-12">
                        <div v-if="result" class="d-flex mb-3">
                            <div><span class="circle">AF</span> Above the fold</div>
                            <div class="ml-3"><span class="circle" style="background-color: blue;">BF</span> Below the fold</div>
                        </div>
                        <img width="100%" :src="result">
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <table v-if="links" class="table table-hover bg-light text-dark">
                            <thead>
                            <tr>
                                <td>Link</td>
                                <td>Position</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(link, index) in links" :key="index">
                                <td>{{link.fullUrl}}</td>
                                <td>{{link.fold}} the fold</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import swal from 'sweetalert';

export default {
    name: 'Fold',
    props:['user'],
    data: function () {
        return {
            links: null,
            result: null,
            filter: {
                width: 800,
                height: 600,
                template: null
            },
        }
    },
    created: function(){
        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        readFileAsString() {
            let file = this.$refs.file.files[0];

            let reader = new FileReader();
            reader.onload = (event) => {
                this.filter.template =  event.target.result;
            };
            reader.readAsText(file);
        },
        calculate() {
            //Load the providers
            this.$root.preloader = true;

            axios.post(`${this.$root.serverUrl}/admin/schedule/fold`, this.filter).then(function(resp){
                this.$root.preloader = false;
                this.result = (resp && resp.data && resp.data.image) ? resp.data.image : null;
                this.links = (resp && resp.data && resp.data.links) ? resp.data.links : null;
            }.bind(this));
        }
    }
}
</script>

<style scoped>
.circle {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    padding: 5px;
    text-align: center;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
}
.searchBtn span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
</style>
